import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import { graphql, Link, StaticQuery } from "gatsby";

import Hero from "../../components/landingPage/Hero";
import Layout from "../../components/Layout";
import Cta from "../../components/Cta";

import Location from "../../components/landingPage/Location";
import PriceList from "../../components/landingPage/PriceList";
import Reviews from "../../components/landingPage/Reviews";
import Dermoscopy from "../../components/landingPage/Dermoscopy";
import Oncology from "../../components/landingPage/Oncology";
import Videodermoscopy from "../../components/landingPage/Videodermoscopy";
import Surgery from "../../components/landingPage/Surgery";
import Melanoma from "../../components/landingPage/Melanoma";
import Abcde from "../../components/landingPage/Abcde";
import Gallery from "../../components/landingPage/Gallery";
import Offer from "../../components/landingPage/Offer";
import Faq from "../../components/landingPage/Faq";
import RiskGroup from "../../components/landingPage/RiskGroup";
import Covid from "../../components/landingPage/Covid";
import ImportantInfo from "../../components/landingPage/ImportantInfo";
import Contact from "../../components/landingPage/Contact";
import ProcedureDetails from "../../components/landingPage/ProcedureDetails";
import WhyUs from "../../components/landingPage/WhyUs";
import ButtonDrw from "../../components/landingPage/ButtonDrw";
import ButtonApptNow from "../../components/landingPage/ButtonApptNow";
import jeremieImg from "../../img/footer/jeremie2.jpeg";
import mazowszeSerceImg from "../../img/footer/mazowsze_serce.png";
import pozyczkiMazowieckieImg from "../../img/footer/pozyczki_mazowieckie.png";
import ueRegionalnyImg from "../../img/footer/ue_regionalny.png";

import {
  GTM_WIDEO,
  GTM_CHIRURGIA,
  GTM_DERMATOLOGIA,
  GTM_E_ONKOLOGIA,
  GTM_NIECHIRURGIA,
  GTM_ONKOLOGIA,
  GTM_LEAD_UMOW_SIE,
} from "../../config/gtm_classes";

const GTM_LOCATION = "warszawa-dermatoskopowe";

//styles
import "../../styles/home.scss";

import SEO from "../../components/SEO";
import CallCta from "../../components/CallCta";
import Button from "../../components/Button";

const REVIEWS = [
  {
    author: "Aleksandra K.",
    city: "Warszawa",
    review:
      "Sposób przeprowadzenia badania sprostał moim oczekiwaniom. Dokładnie, szczegółowo i kompleksowo wszystko sprawdzone.",
  },
  {
    author: "Krystyna H.",
    city: "Warszawa",
    review:
      "Już Panie w recepcji są niezwykle uprzejme. Miałam umówioną wizytę, ale wypadło mi coś w pracy. Zadzwoniłam, aby ją przełożyć z czym nie było problemu. Wczoraj byłam na sprawdzeniu swoich znamion. Wszystko jest Ok, dziękuję za miłą konsultację.",
  },
  {
    author: "Ryszard M.",
    city: "Warszawa",
    review:
      "Ładnie urządzone miejsce, dobrzy specjaliści. Szczerze polecam to miejsce wszystkim, których niepokoją jakieś znamiona.",
  },
];

const PRICES = [
  {
    isOpened: true,
    categoryName: "Badanie znamion - wideodermatoskopia",
     
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_WIDEO}`}
        data-apptnow-widget="10f98eb0-fc26-11ec-915c-d181b41bbf96"
      />
    ),
    items: [
      {
        price: "400",
        label: "wideodermatoskopia wszystkich znamion - osoba dorosła",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "300",
        label:
          "wideodermatoskopia wszystkich znamion - dziecko do 12 roku życia",
        sub:
          "z mapowaniem wytypowanych przez lekarza  zmian wymagających monitorowania",
      },
      {
        price: "250",
        label:
          "wideodermatoskopia - wizyta kontrolna osoby dorosłej do 6 mies. od pełnego badania",
        sub:
          "badanie wytypowanych przez lekarza na poprzedniej wizycie znamion do monitorowania",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Chirurgiczne usuwanie znamion",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_CHIRURGIA}`}
        data-apptnow-widget="26155280-d1ff-11ec-a8d3-ebc463f9ed66"
      />
    ),
    items: [
      {
        price: "200",
        label: "konsultacja chirurgiczna",
        sub: "zabiegi chirurgiczne są wykonywane u pacjentów od 6 r.ż.",
      },
      {
        price: "480",
        label: "usunięcie 1 znamienia do 1,5cm",
        sub:
          "w standardowej lokalizacji - tułów/kończyny - w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "280",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty do 1,5cm",
        sub: "w standardowej lokalizacji - tułów/kończyny",
      },
      {
        price: "580",
        label:
          "usunięcie 1 znamienia powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
        sub: "w cenie kontrola, zdjęcie szwów",
      },
      {
        price: "350",
        label:
          "*usunięcie dodatkowego znamienia w trakcie jednej wizyty powyżej 1,5cm lub w szczególnej lokalizacji np. twarz",
      },
      {
        price: "150",
        label: "badanie histopatologiczne jednej zmiany",
      },
      {
        price: "300",
        label: "biopsja zmiany skórnej",
        
      },
      {
        price: "0",
        label: "wizyta kontrolna po zabiegu ze zdjęciem szwów",
        sub: "*dla pacjentów, którzy odbyli zabieg w Twoje Znamiona",
      },
      {
        price: "100",
        label: "zdjęcie szwów",
        sub: "*dla pacjentów, którzy wykonali zabieg poza Twoje Znamiona",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Laserowe usuwanie zmian skórnych",
    categorySub: "m.in brodawki, włókniaki, kurzajki",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_NIECHIRURGIA}`}
        data-apptnow-widget="1f56c5e0-fc26-11ec-915c-d181b41bbf96"
      />
    ),
    items: [
      {
        price: "350",
        label: "usunięcie 1 zmiany skórnej do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "50",
        label:
          "*usunięcie dodatkowej zmiany skórnej w trakcie tej samej wizyty do 0,5 cm",
        sub: "w standardowej lokalizacji - tułów/ kończyny",
      },
      {
        price: "400",
        label:
          "usunięcie 1 zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej zmiany powyżej 0,5 cm lub w szczególnej lokalizacji np. twarz/ włosy",
      },
      {
        price: "400",
        label:
          "usunięcie 1 brodawki wirusowej - kurzajki",
      },
      {
        price: "100",
        label:
          "*usunięcie dodatkowej brodawki wirusowej - kurzajki w trakcie tej samej wizyty",
          sub: "w przypadku rozległych zmian o liczbie zabiegów decyduje lekarz",
      },
      {
        price: "200",
        label: "konsultacja lekarska",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje onkologiczne - czerniak",
    button: () => (
      <ButtonApptNow
        className={`${GTM_LOCATION} ${GTM_E_ONKOLOGIA}`}
        data-apptnow-widget="57c029a0-9b25-11ed-b366-89b05425b063"
      />
    ),
    items: [
      {
        price: "200",
        label: "e-wizyta - wideokonferencja",
      },
    ],
  },
  {
    isOpened: false,
    categoryName: "Konsultacje dermatologiczne",
    items: [
      {
        price: "250",
        label: "konsultacja dermatologiczna",
        sub: "inne schorzenia skóry: trądzik, łuszczyca, grzybica itp.",
        button: () => (
          <ButtonApptNow
            className={`${GTM_LOCATION} ${GTM_DERMATOLOGIA}`}
            data-apptnow-widget="33996350-fc26-11ec-915c-d181b41bbf96"
          />
        ),
      },
    ],
  },
];


const OFFERS = [
  {
    type: "badanie",
    title: "Badanie znamion",
    desc:
      "Dermatoskopowe badanie wszystkich znamion na skórze w formie wideodermatoskopii z mapowaniem zmian wytypowanych przez lekarza do obserwacji oraz archiwizacją wykonanych zdjęć.",
      link:"#cennik",
  },
  {
    type: "chirurgiczne",
    title: "Usuwanie znamion",
    desc:
      "Chirurgiczne usuwanie podejrzanych pieprzyków wytypowanych przez lekarza w trakcie badania wideodermatoskopowego z wykonaniem histopatologicznej oceny usuniętej zmiany skórnej.",
      link:"/warszawa/usuwanie-znamion-pieprzykow",
  },
  {
    type: "niechirurgiczne",
    title: "Zabiegi laserowe",
    desc:
      "Laserowe usuwanie pieprzyków jest zarezerwowane dla łagodnych zmian skórnych. Lekarz po wykonaniu badania wideodermatoskopowego wszystkich znamion kwalifikuje znamiona do odpowiedniej metody zabiegowej.",
      link:"/warszawa/laserowe-usuwanie-znamion-pieprzykow-wlokniakow-brodawek-kurzajek",
  },
];

const INFOS = [
  {
    title: "Ile trwa badanie?",
    desc:
      "Badanie wszystkich znamion - 30-40 min. Badanie kontrolne znamion wyznaczonych przez lekarza - 15-20 min.",
  },
  {
    title: "Jak się przygotować do badania?",
    desc:
      "Przed badaniem należy wykonać demakijaż oraz skrócić owłosienia w miejscach, w których znajdują się znamiona, celem poprawy jakości obrazu dermatoskopowego.",
  },
  {
    title: "Jakim urządzeniem badamy?",
    desc:
      "Pacjent badany jest za pomocą nowoczesnego sprzętu - wideodermatoskopu Fotofinder Portable Medicam 1000 Full HD",
  },
];



const AdditionalFooter = () => {
  return (
    <div>
      <div>
        Wspiera nas{" "}
        <strong>Mazowiecki Regionalny Fundusz Pożyczkowy Sp. z o.o.</strong> |
        www.pozyczkimazowieckie.pl
      </div>
      <div className="partner_logos">
        <img src={jeremieImg} alt="Jeremie 2" />
        <img src={mazowszeSerceImg} alt="Mazowsze Serce" />
        <img
          src={pozyczkiMazowieckieImg}
          alt="Pożyczki Mazowieckie"
          width={"100px"}
        />
        <img src={ueRegionalnyImg} alt="UE" />
      </div>
    </div>
  );
};

const CtaUmowSie = () => {
  return (
    <AnchorLink href="#cennik" title="Umów się" className={GTM_LEAD_UMOW_SIE}>
      Umów się
    </AnchorLink>
  );
};

const CtaCennik = () => {
  return (
    <AnchorLink href="#cennik" title="Cennik">
      Ile to kosztuje?
    </AnchorLink>
  );
};

const WarszawaBadanieZnamionPage = ({ graph, location }) => {
  const FAQS = [
    {
      image: graph.faqs[1].image,
      imageMobile: graph.faqs[1].imageMobile,
      title: "Jakie są przeciwwskazania do wykonania badania?",
      text:
        "Dermatoskopia jest całkowicie nieinwazyjną metodą diagnostyki, nie ma żadnych przeciwwskazań do jej wykonania. Badaniu mogą poddać się kobiety w ciąży, karmiące piersią, osoby obciążone poważnymi chorobami przewlekłymi czy też dzieci. Dermatoskopia polega na wykonywaniu zdjęć znamion w powiększeniu stąd może zostać wykonana u każdego.",
    },
    {
      image: graph.faqs[2].image,
      imageMobile: graph.faqs[2].imageMobile,
      title: "Czy czerniak boli?",
      text:
        "Dolegliwości bólowe nie są charakterystycznym objawem dla czerniaka, natomiast ich obecność bądź brak nie przesądza w żadnym wypadku diagnozy. Czerniak może rozwijać się po cichu, bez bólu i zupełnie niezauważalnie i z roku na rok zajmować coraz większą powierzchnię Twojej skóry. Najczęstszy czerniak szerzący się powierzchownie (SSMM - superficial spreading melanoma malignum), może wzrastać niezauważalnie nawet kilka lat!",
    },
  ];

  return (
    <Layout
      constactCtaComponent={<CtaUmowSie />}
      location={location}
      additionalFooterComponent={<AdditionalFooter />}
    >
      <SEO
        title="Centrum Badania Znamion w Warszawie - Dermatoskopia - twojeznamiona.pl"
        description="Warszawskie Centrum Dermatoskopowego Badania Znamion. Warszawa - kompleksowa diagnostyka znamion. Badamy wszystkie pieprzyki na ciele. Wideodermatoskopia z mapowaniem. Dlaczego dermatoskopia jest najlepszą metodą diagnostyki zmian skórnych? Ile kosztuje badanie znamion w Warszawie - cennik. Dermatologia."
        keywords="badanie znamion, warszawa, dermatoskopia, wideodermatoskopia"
      />
      <Hero
        h1="Warszawskie Centrum Dermatoskopowego Badania Znamion"
        oneLiner="Wszystkie swoje działania skupiamy na kompleksowej diagnostyce znamion, aby wyeliminować nowotwory skóry z życia milionów ludzi."
        oneLinerMore="Upewnij się, że czerniak nie ukrywa się w jednym z niepozornych pieprzyków na Twojej skórze."
        ctaPrimary={<CtaUmowSie />}
        ctaSecondary={<CtaCennik />}
      />
      <Offer className="home_offer" data={OFFERS} />
      <Melanoma className="home_melanoma" />
      <WhyUs className="home_why_us with_no_padding_top" />
      <PriceList className="home_price_list" data={PRICES} ifProgramProfilaktykiAvailable/>

      <Contact className="home_contact" where="w Warszawie" city="Warszawa" />
      <Location
        className="home_location"
        coords={{
          lat: 52.234631817750476,
          lng: 20.959518637081253,
        }}
        googleMarker={{
          data: ["ul. Sokołowska 9/U-12", "01-142 Warszawa"],
          link: "https://g.page/twoje-znamiona-warszawa?share",
        }}
        additionalDetails="Centrum Badania Znamion znajduje się w tym samym pasażu co Auchan - Centrum Medyczne Medify.me. Przed Auchan są dostępne miejsca parkingowe."
        locationInfo={[
          "Centrum Medyczne Medify.me",
          "ul. Sokołowska 9/U-12",
          "Dzielnica - Wola",
          "01-142 Warszawa",
        ]}
        hoursInfo={["pon. - pt.: 08:00 - 20:30,", "sob. 9:00-14:00"]}
        transportationInfo={[
          "Parking podziemny - sklep Auchan w tym samym budynku",
          "Przystanek tramwajowy:",
          "Sokołowska",
          "Stacja metra: Młynów",
        ]}
      />
      <Gallery
        className="home_gallery"
        data={graph.gallery}
        city="warszawa"
        imageTitlePostfix="w Warszawie"
      />

      <Reviews className="home_reviews" data={REVIEWS} />

      <ProcedureDetails
        className="home_two_col_section_right_img"
        h2="Przebieg badania wideodermatoskopowego w Warszawie"
      />
      <ImportantInfo className="home_important_info" data={INFOS} />
      <Dermoscopy
        className="home_two_col_section_right_img with_no_padding_top"
        reverse={false}
      />
      <Videodermoscopy className="home_two_col_section_left_img" />
      <RiskGroup className="home_risk_group" />
      <Surgery className="home_two_col_section_left_img" reverse={false} />
      <Faq className="home_faq" data={FAQS} />
      <Abcde className="home_abcde" />
      <Oncology className="home_two_col_section_left_img with_no_padding_top with_no_padding_bottom" />
      <Cta className="home_cta" constactCtaComponent={<CtaUmowSie />} />
      <CallCta />
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        ImageGallery1: allFile(
          filter: {
            relativePath: { regex: "/Centrum Badania Znamion w Warszawie.jpg/" }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery2: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_1.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery3: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_2.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery4: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_4.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery5: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_5.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ImageGallery6: allFile(
          filter: {
            relativePath: {
              regex: "/Centrum Badania Znamion w Warszawie_6.jpg/"
            }
          }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 550, maxHeight: 350, quality: 90) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1Image: allFile(filter: { relativePath: { regex: "/faq1.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2Image: allFile(filter: { relativePath: { regex: "/faq2.png/" } }) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 144, maxHeight: 269, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq1ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-1-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        Faq2ImageMobile: allFile(
          filter: { relativePath: { regex: "/faq-2-mobile.png/" } }
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 288, maxHeight: 179, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <WarszawaBadanieZnamionPage
        graph={{
          gallery: [
            data.ImageGallery1.nodes[0],
            data.ImageGallery2.nodes[0],
            data.ImageGallery3.nodes[0],
            data.ImageGallery4.nodes[0],
            data.ImageGallery5.nodes[0],
            data.ImageGallery6.nodes[0],
          ],
          faqs: {
            1: {
              image: data.Faq1Image.nodes[0],
              imageMobile: data.Faq1ImageMobile.nodes[0],
            },
            2: {
              image: data.Faq2Image.nodes[0],
              imageMobile: data.Faq2ImageMobile.nodes[0],
            },
          },
        }}
        {...props}
      />
    )}
  />
);
